import { useEffect, useState } from "react";
import { connect, ResolveThunks } from "react-redux";
import { noSleep } from "../../var";
import { LoadingSpinner } from "../../components/loading_spinner";
import SongList from "../../components/song_list";
import { rootSelectors, RootState } from "../../state/root_reducer";
import { actions } from "../../state/slices/songs";
import {
  actions as userActions,
  SongFeedbackAndSeed,
} from "../../state/slices/user";
import SeedsModal from "../../components/seeds_modal";
import ExportModal, {
  ExportPlaylistParams,
} from "../../components/export_modal";
import { useNavigate } from "react-router";

const DEFAULT_ITEMS = 50;
const DEFAULT_DEPTH = 500;

interface OwnProps {
  includeScore: boolean;
}

interface StateProps {
  activeSeeds: SongFeedbackAndSeed[];
  recommendations: SongRecommendation[] | null;
  songFeedback: SongFeedback[] | null;
}

interface DispatchProps {
  onFetchSongs: typeof actions.fetchRecommendationsFromLikes;
  onClearRecs: typeof actions.clearRecommendationsFromLikes;
  onAddToPlaylist: typeof actions.addToPlaylist;
  onFetchSongFeedback: typeof userActions.fetchSongFeedback;
  onUpdateSeedStatus: typeof userActions.updateSeedStatus;
  onUpdateSelectedSongAndFetchArt: typeof actions.updateSelectedSongAndFetchArt;
  onExportPlaylist: typeof userActions.createUserPlaylist;
}

type FreePlayProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

export function FreePlay(props: FreePlayProps): JSX.Element {
  const navigate = useNavigate();

  const [fetching, setFetching] = useState(false);
  const [inSeedsModal, setInSeedsModal] = useState(false);
  const [inExportModal, setInExportModal] = useState(false);
  const [depth, setDepth] = useState(DEFAULT_DEPTH);
  const [count, setCount] = useState(DEFAULT_ITEMS);
  const [popPenalty, setPopPenalty] = useState(0.5);
  const [includeSeeds, setIncludeSeeds] = useState(false);
  const [includeLikes, setIncludeLikes] = useState(false);
  const [algo, setAlgo] = useState<"v1" | "v2">("v2");

  function fetchRecs(
    top: boolean,
    depth: number,
    count: number,
    includeSeeds: boolean,
    includeLikes: boolean,
    popPenalty: number,
    algo: "v1" | "v2"
  ): void {
    setFetching(true);

    props.onClearRecs();
    props.onFetchSongs(
      count,
      depth,
      top,
      includeSeeds,
      includeLikes,
      popPenalty,
      algo
    );
  }

  useEffect(() => {
    if (props.recommendations !== null) {
      setFetching(false);
    }
  }, [props.recommendations]);

  function openModalAndLoadSeeds(): void {
    if (props.songFeedback === null) {
      props.onFetchSongFeedback();
    }

    setInSeedsModal(true);
  }

  function closeModal(): void {
    setInSeedsModal(false);
  }

  function saveModalSeeds(songs: Song[]): void {
    props.onUpdateSeedStatus(songs);

    closeModal();
  }

  function selectSong(song: Song): void {
    props.onUpdateSelectedSongAndFetchArt(song, () => navigate("/songs"));
  }

  async function exportPlaylist(params: ExportPlaylistParams): Promise<void> {
    await props.onExportPlaylist({
      ...params,
      trackIds: (props.recommendations ?? [])?.map((rs) => rs.song.spotify_id),
    });
    setInExportModal(false);
  }

  function render(): JSX.Element {
    return (
      <div className="p-2 md:p-4 w-full flex flex-col">
        <div className="col flex-col">
          <div
            className="flex flex-row items-center mt-2 mb-2 border-b-2 pb-2"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div className="pl-2 sm:pl-4 border-gray-400">
              <button
                className="button-generic mr-2 sm:mr-4 text-sm sm:text-lg"
                onClick={() => openModalAndLoadSeeds()}
              >
                🌱 Pick Seeds ({props.activeSeeds.length})
              </button>
            </div>
            <div className="border-l-4 pl-2 sm:pl-4 border-gray-400 flex flex-col">
              <div className="flex flex-row">
                <div style={{ width: 90 }}>{`Depth: ${depth}`}</div>
                <div className="flex">
                  <input
                    className="ml-2"
                    onChange={(e) => setDepth(Number(e.target.value))}
                    type="range"
                    min="150"
                    max="1500"
                    value={depth}
                  />
                </div>
              </div>
              <div className="flex flex-row">
                <div style={{ width: 90 }}>{`Count: ${count}`}</div>
                <div className="flex">
                  <input
                    className="ml-2"
                    onChange={(e) => setCount(Number(e.target.value))}
                    type="range"
                    min="10"
                    max="150"
                    value={count}
                  />
                </div>
              </div>
              <div className="flex flex-row">
                <div style={{ width: 90 }}>{`Obsc: ${popPenalty}`}</div>
                <div className="flex">
                  <input
                    className="ml-2"
                    onChange={(e) => setPopPenalty(Number(e.target.value))}
                    type="range"
                    min="0.01"
                    max="0.99"
                    step="0.01"
                    value={popPenalty}
                  />
                </div>
              </div>
            </div>
            <div className="border-l-4 pl-2 sm:pl-4 ml-2 sm:ml-4 border-gray-400 flex flex-col">
              <div className="flex flex-row">
                <div>
                  <input
                    onChange={(e) => setIncludeSeeds(e.target.checked)}
                    type="checkbox"
                    checked={includeSeeds}
                  />
                </div>
                <div className="ml-2">{`Include Seeds`}</div>
              </div>
              <div className="flex flex-row">
                <div>
                  <input
                    onChange={(e) => setIncludeLikes(e.target.checked)}
                    type="checkbox"
                    checked={includeLikes}
                  />
                </div>
                <div className="ml-2">{`Include Likes`}</div>
              </div>
              <div className="flex flex-row">
                <div>
                  <input
                    onChange={(e) => setAlgo(e.target.checked ? "v2" : "v1")}
                    type="checkbox"
                    checked={algo === "v2"}
                  />
                </div>
                <div className="ml-2">{`Use V2 Algo`}</div>
              </div>
            </div>
          </div>
          <div
            className="flex flex-row items-center mt-2 mb-2"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div>
              <button
                className="button-generic mr-2 sm:mr-4 text-sm sm:text-lg"
                onClick={() => {
                  fetchRecs(
                    false,
                    depth,
                    count,
                    includeSeeds,
                    includeLikes,
                    popPenalty,
                    algo
                  );
                }}
              >
                🔀️ Shuffle
              </button>
              <button
                className="button-generic text-sm sm:text-lg"
                onClick={() => {
                  fetchRecs(
                    true,
                    depth,
                    count,
                    includeSeeds,
                    includeLikes,
                    popPenalty,
                    algo
                  );
                }}
              >
                🔝 Top
              </button>
              {Number(props.recommendations?.length) > 0 ? (
                <span className="border-l-4 pl-2 sm:pl-4 ml-2 sm:ml-4 border-gray-400">
                  <button
                    className="button-generic mr-2 sm:mr-4 text-sm sm:text-lg"
                    onClick={() => setInExportModal(true)}
                  >
                    ↗️ Export as Spotify Playlist
                  </button>
                </span>
              ) : null}
            </div>
          </div>
        </div>
        {fetching && <LoadingSpinner border={false} />}
        {props.recommendations && (
          <SongList
            maxSongs={props.recommendations.length}
            songs={props.recommendations.map((sr) => sr.song)}
            otherdata={
              props.includeScore
                ? props.recommendations.map((sr) => sr.score + "")
                : undefined
            }
            otherdataName={props.includeScore ? "Score" : undefined}
            selectSongHandler={(s) => selectSong(s)}
          />
        )}
        {inSeedsModal && props.songFeedback && (
          <SeedsModal
            onCancel={() => closeModal()}
            onSaveSeeds={(songs) => saveModalSeeds(songs)}
          />
        )}
        {inExportModal && props.recommendations?.length && (
          <ExportModal
            descriptionHint={`Created from ${
              props.activeSeeds[0].song.name
            } and ${props.activeSeeds.length - 1} more seeds.`}
            songCount={props.recommendations.length}
            onCancel={() => setInExportModal(false)}
            onExport={(params) => exportPlaylist(params)}
          />
        )}
      </div>
    );
  }

  return render();
}

function mapState(state: RootState): StateProps {
  return {
    activeSeeds: rootSelectors.user.getActiveSeeds(state),
    recommendations: state.songs.songRecsFromLikes,
    songFeedback: state.user.songFeedbackByGid
      ? Object.entries(state.user.songFeedbackByGid).map(([_, sf]) => sf)
      : state.user.songFeedbackByGid,
  };
}

const mapDispatch: DispatchProps = {
  onFetchSongs: actions.fetchRecommendationsFromLikes,
  onClearRecs: actions.clearRecommendationsFromLikes,
  onAddToPlaylist: actions.addToPlaylist,
  onFetchSongFeedback: userActions.fetchSongFeedback,
  onUpdateSeedStatus: userActions.updateSeedStatus,
  onUpdateSelectedSongAndFetchArt: actions.updateSelectedSongAndFetchArt,
  onExportPlaylist: userActions.createUserPlaylist,
};

export default connect(mapState, mapDispatch)(FreePlay);
