import { useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import FreePlay from "../../pages/free_play";
import ManageLikes from "../../pages/manage_likes";
import SongsPage from "../../pages/songs";
import DebugPage from "../../pages/debug_page";
import Authorization from "../authorization";
import SideNavBar from "../side_nav_bar";
import SpotifyBar from "../spotify_bar";
import { REDIRECT_URL } from "./routes";
import PrivacyPage from "../../pages/privacy";
import TermsPage from "../../pages/terms";

const scopes = [
  "user-read-private", // needed for subscription level and Web Playback SDK
  "user-read-email", // needed for Web Playback SDK level
  "streaming", // needed for Web Playback SDK
  "user-read-playback-state",
  "user-modify-playback-state",
  "playlist-read-private", // needed to import private playlist
  "playlist-read-collaborative", // needed to import collaborative playlists
  "user-library-read", // needed to read favorites
  "user-library-modify", // needed to 'favorite' a song
  "playlist-modify-private", // needed to create prviate playlists
  "playlist-modify-public", // needed to create public playlists
];

const SPOTIFY_AUTH_URL = "https://accounts.spotify.com/authorize";
const CLIENT_ID = "b4954d9c424d4d93bcb1001b6ff59baf";

function getWindowDimensions(): { width: number; height: number } {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function getAuthorizeHref(): string {
  const url = `${SPOTIFY_AUTH_URL}?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(
    REDIRECT_URL
  )}&scope=${scopes.join("%20")}&response_type=code`;

  return url;
}

export function RouteMaster(): JSX.Element {
  const location = useLocation();
  const { width } = useWindowDimensions();

  function render(): JSX.Element {
    return (
      <div className="">
        <Authorization />
        <div className="min-h-screen max-h-screen w-screen bg-gradient-to-r from-purple-200 to-gray-200 bg-repeat flex flex-col">
          <SpotifyBar loginUrl={getAuthorizeHref()} />
          <div
            className="flex flex-row flex-grow"
            style={{ overflowY: "hidden" }}
          >
            <SideNavBar url={location.pathname} />
            <div className="bg-gray-100 w-full flex">
              <Routes>
                <Route path="/songs" element={<SongsPage />} />
                <Route
                  path="/play"
                  element={<FreePlay includeScore={width >= 450} />}
                />
                <Route path="/likes" element={<ManageLikes />} />
                <Route path="/" element={<Navigate to="/songs" />} />
                <Route path="/debug" element={<DebugPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/terms" element={<TermsPage />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return render();
}
