import { connect, ResolveThunks } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../state/root_reducer";
import { actions as userActions } from "../../state/slices/user";
import { actions as songActions } from "../../state/slices/songs";
import SongList from "../../components/song_list";
import { LoadingSpinner } from "../../components/loading_spinner";
import { useNavigate } from "react-router";
import ImportPlaylistModal from "../../components/import_modal";

interface StateProps {
  songFeedback: SongFeedback[] | null;
}

interface DispatchProps {
  onFetchSongFeedback: typeof userActions.fetchSongFeedback;
  onRemoveFeedback: typeof userActions.removeFeedback;
  onUpdateSelectedSongAndFetchArt: typeof songActions.updateSelectedSongAndFetchArt;
  onFetchUserPlaylists: typeof userActions.fetchUserPlaylists;
  onImportPlaylist: typeof userActions.importUserPlaylist;
}

type ManageLikesProps = StateProps & ResolveThunks<DispatchProps>;

export function getIconForFeedback(feedback: FeedbackType): string | null {
  switch (feedback) {
    case "Seed":
      return "🌱";
    case "Like":
      return "👍";
    case "Dislike":
      return "👎";
    case "Do Not Play":
      return "🚫";
    default:
      return null;
  }
}

const REFRESH_INTERVAL = 5 * 60 * 1000;

function ManageLikes(props: ManageLikesProps): JSX.Element {
  const navigate = useNavigate();

  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [inImportModal, setInImportModal] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  function openImportModal() {
    props.onFetchUserPlaylists();

    setInImportModal(true);
  }

  useEffect(() => {
    if (props.songFeedback === null || shouldUpdate) {
      console.log("Fetching song feedback..");

      props.onFetchSongFeedback();

      setShouldUpdate(false);
    }
    console.log("Setting refresh timer for feedback");
    setTimeout(() => {
      console.log(`Setting shouldUpdate to true`);
      setShouldUpdate(true);
    }, REFRESH_INTERVAL);
  }, [shouldUpdate]);

  function selectSong(song: Song): void {
    props.onUpdateSelectedSongAndFetchArt(song, () => navigate("/songs"));
  }

  function render(): JSX.Element {
    return props.songFeedback === null ? (
      <LoadingSpinner border={false} />
    ) : (
      renderSongFeedback()
    );
  }

  function renderSongFeedback(): JSX.Element {
    return (
      <div className="p-2 sm:p-4 pb-0 w-full flex flex-col mt-4">
        <button
          className="button-generic mr-2 sm:mr-4 text-sm sm:text-lg mb-4"
          onClick={() => openImportModal()}
        >
          ↙️ Import from Spotify Playlist
        </button>
        <SongList
          songs={props.songFeedback!.map((sf) => sf.song)}
          selectSongHandler={(s) => selectSong(s)}
          deleteSongHandler={(s) => props.onRemoveFeedback(s.gid)}
          otherdata={props.songFeedback!.map(
            (sf) => getIconForFeedback(sf.feedback) || "?"
          )}
          otherdataName="Feedback"
          maxSongs={1000}
        />
        {inImportModal && (
          <ImportPlaylistModal
            isImporting={isImporting}
            onCancel={() => setInImportModal(false)}
            onImport={async (playlist) => {
              setIsImporting(true);
              await props.onImportPlaylist(playlist.id);
              setInImportModal(false);
              setIsImporting(false);
            }}
          />
        )}
      </div>
    );
  }

  return render();
}

function mapState(state: RootState): StateProps {
  return {
    songFeedback: state.user.songFeedbackByGid
      ? Object.entries(state.user.songFeedbackByGid).map(([_, sf]) => sf)
      : state.user.songFeedbackByGid,
  };
}

const mapDispatch: DispatchProps = {
  onFetchSongFeedback: userActions.fetchSongFeedback,
  onRemoveFeedback: userActions.removeFeedback,
  onUpdateSelectedSongAndFetchArt: songActions.updateSelectedSongAndFetchArt,
  onFetchUserPlaylists: userActions.fetchUserPlaylists,
  onImportPlaylist: userActions.importUserPlaylist,
};

export default connect(mapState, mapDispatch)(ManageLikes);
