import { useState } from "react";
import { connect, ResolveThunks } from "react-redux";
import { RootState } from "../../state/root_reducer";
import { LoadingSpinner } from "../loading_spinner";

interface OwnProps {
  isImporting: boolean;
  onImport: (playlist: FeaturedPlaylistResult) => void;
  onCancel: () => void;
}

interface StateProps {
  userPlaylists: FeaturedPlaylistResult[] | null;
}

interface DispatchProps {}

export type ImportPlaylistModalProps = OwnProps &
  StateProps &
  ResolveThunks<DispatchProps>;

function ImportPlaylistModal(props: ImportPlaylistModalProps): JSX.Element {
  const [selectedPlaylist, setSelectedPlaylist] = useState<string | null>(null);

  console.log(`Found ${props.userPlaylists?.length} playlists`);

  function render(): JSX.Element {
    return (
      <div
        className="fixed z-50 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex flex-col align-center space-y-4">
                <p>Select Spotify playlist to import as seeds:</p>
                {props.userPlaylists ? (
                  props.userPlaylists.map((p) => {
                    return (
                      <div className="flex flex-row items-center">
                        <input
                          className="p-2 ml-4 mr-4 bg-gray-200 border-1"
                          type="checkbox"
                          checked={selectedPlaylist === p.id}
                          onChange={(e) =>
                            setSelectedPlaylist(e.target.checked ? p.id : null)
                          }
                        />
                        <span>{p.name}</span>
                      </div>
                    );
                  })
                ) : (
                  <LoadingSpinner border={false} />
                )}
              </div>
              <div className="mt-2"></div>
            </div>
            <div className="bg-gray-200 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                disabled={props.isImporting}
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() =>
                  props.onImport(
                    props.userPlaylists!.find((p) => p.id === selectedPlaylist)!
                  )
                }
              >
                Import
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => props.onCancel()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return render();
}

function mapState(rootState: RootState): StateProps {
  return {
    userPlaylists: rootState.user.userPlaylists,
  };
}

const mapDispatch: DispatchProps = {};

export default connect(mapState, mapDispatch)(ImportPlaylistModal);
